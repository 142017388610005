import { Controller } from '@hotwired/stimulus';
import { get, post } from '@rails/request.js';

const InvoiceTypes = {
  premium: 'premium',
  fee: 'fee',
  other: 'other',
};

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

export default class extends Controller {
  static targets = [
    'policy',
    'referenceType',
    'referenceId',
    'annotation',
    'total',
    'totalError',
    'taxField',
    'brokerageCommissionPercentage',
    'brokerageCommissionAmount',
  ];

  static values = {
    dealId: String,
    invoiceId: String,
    selectedIndex: Number,
    type: String,
    isAdmin: Boolean,
  };

  connect() {
    // Reset from other line item validations.
    this.disableSaveButtons(false);
    this.policyTarget.addEventListener('change', this.policyChanged.bind(this));
    this.referenceTypeTarget.addEventListener('change', this.referenceTypeChanged.bind(this));
    this.referenceIdTarget.addEventListener('change', this.referenceIdChanged.bind(this));
    this.brokerageCommissionPercentageTarget.addEventListener(
      'input',
      this.updateBrokerageCommissionAmount.bind(this)
    );
    this.totalTarget.addEventListener('input', this.debouncedTotalChanged.bind(this));
    if (this.isAdminValue) {
      this.createBankIdListener();
      document.getElementById('bank-id').addEventListener('input', this.validateCanSave.bind(this));
    }
    this.validateTotalInvoiced();
    this.validateCanSave();
  }

  brokerageCommissionPercentageTargetConnected(_event) {
    this.brokerageCommissionPercentageTarget.addEventListener(
      'input',
      this.updateBrokerageCommissionAmount.bind(this)
    );
    this.updateBrokerageCommissionAmount();
  }

  brokerageCommissionPercentageTargetChanged(_event) {
    this.updateBrokerageCommissionAmount();
  }

  updateBrokerageCommissionAmount(_event) {
    const amount_to_invoice = this.totalTarget.value;
    const brokerage_commission_percentage = this.brokerageCommissionPercentageTarget.value;

    if (this.policyTarget.value === '') {
      return;
    }

    const request = post(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/update_brokerage_commission_amount`,
      {
        responseKind: 'turbo-stream',
        body: {
          index: this.selectedIndexValue,
          amount_to_invoice,
          brokerage_commission_percentage,
        },
      }
    );

    request.perform;
  }

  // DOM way of doing TargetConnected
  createBankIdListener() {
    const bankIdElement = document.getElementById('bank-search-container');
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (_mutationList, _observer) => {
      // We really don't care which "event" is happening here, just that an event occurred to refire validateSave.
      this.validateCanSave();
    };

    const observer = new MutationObserver(callback);

    observer.observe(bankIdElement, config);
  }

  disableSaveButtons(disabled = true) {
    const buttons = document.querySelectorAll(
      '#submit-invoice-btn, #save-invoice-btn, #approve-invoice-btn'
    );
    buttons.forEach((button) => {
      button.disabled = disabled;
    });
  }

  debouncedTotalChanged = debounce(this.totalChanged.bind(this), 1000);
  // TargetConnected pieces are for when turbo_stream updates the DOM - we need to rebind the event listeners
  totalTargetConnected(_event) {
    this.totalTarget.addEventListener('input', this.debouncedTotalChanged.bind(this));
  }

  // Built in Dom/Stimulus event
  annotationTargetConnected(_event) {
    if (this.typeValue != InvoiceTypes.other) {
      this.annotationTarget.addEventListener('input', this.updateAnnotation.bind(this));
    }
  }

  totalErrorTargetConnected(_event) {
    this.validateCanSave();
  }

  totalErrorTargetDisconnected(_event) {
    this.validateCanSave();
  }

  referenceIdTargetConnected(_event) {
    this.referenceIdTarget.addEventListener('change', this.referenceIdChanged.bind(this));
  }

  validateBankingInfo() {
    // Bank ID is outside of Stimulus controller/target - grab via Dom selector
    const bankId = document.getElementById('bank-id');
    const hasBankingInfo = bankId.value.length > 0;

    if (hasBankingInfo) {
      return this.disableSaveButtons(false);
    } else {
      return this.disableSaveButtons(true);
    }
  }

  // Shared Between Invoice Types
  validateCanSave() {
    // Ensure there are no line item errors (totals, etc)
    const hasTotalErrors = this.totalErrorTargets.length !== 0;

    if (hasTotalErrors) {
      this.disableSaveButtons(true);
    }

    // if there are ANY empty policy fields, disable the save buttons
    const policyTargets = document.querySelectorAll('[data-premium-line-item-target="policy"]');
    const hasEmptyPolicyFields = Array.from(policyTargets).some((policy) => policy.value === '');

    // if there are ANY empty total fields, disable the save buttons
    const totalTargets = document.querySelectorAll('[data-premium-line-item-target="total"]');
    const hasEmptyTotalFields = Array.from(totalTargets).some((total) => total.value === '');

    if (hasEmptyPolicyFields || hasEmptyTotalFields) {
      this.disableSaveButtons(true);
      return;
    }

    // call validateBankingInfo if isAdmin is true
    if (this.isAdminValue) {
      return this.validateBankingInfo();
    } else {
      this.disableSaveButtons(false);
      return;
    }
  }

  updateAnnotation(event) {
    const annotation = document.getElementById(`line-item-annotation-${this.selectedIndexValue}`);
    annotation.innerHTML = ` (${event.target.value})`;
  }

  referenceIdChanged(_event) {
    this.totalTarget.value = '';
    this.setTaxPercentages(this.referenceTypeTarget.value, this.referenceIdTarget.value);
    this.validateTotalInvoiced();
  }

  referenceTypeChanged(event) {
    this.totalTarget.value = '';
    if (this.policyTarget.value !== '') {
      this.setItemizable(event);
    }
    this.setTaxPercentages();
    this.validateTotalInvoiced();
  }

  policyChanged(event) {
    this.totalTarget.value = '';
    this.setPolicy(event);
    this.setItemizable(event);
    this.setTaxPercentages();
    this.validateTotalInvoiced(event);
    this.updatePolicyNumbers();
    this.validateCanSave();
  }

  updatePolicyNumbers() {
    // This goes beyond the scope of the single line item, query for ALL line item targets here.
    // Needed to leave in the line item controller for the other event listeners to trigger this function.
    const elements = document.querySelectorAll('[data-premium-line-item-target="policy"]') || [];
    const policyTargets = Array.from(elements);

    const policyIds = policyTargets
      .map((policy) => {
        if (policy.value === '') {
          return null;
        }
        return policy.value;
      })
      .filter((policy) => policy !== null);

    const request = post(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/update_policy_numbers`,
      {
        responseKind: 'turbo-stream',
        body: {
          policy_ids: policyIds,
        },
      }
    );

    request.perform;
  }

  totalChanged(_event) {
    this.validateTotalInvoiced();
    this.validateCanSave();
    this.updateBrokerageCommissionAmount();
    this.setTaxPercentages();
  }

  setPolicy(event) {
    const selectedPolicy = event.target.value;
    if (selectedPolicy.length === 0) {
      this.taxFieldTargets.forEach((taxField) => {
        taxField.innerHTML = '';
      });
      return;
    }

    const request = get(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/set_policy/${this.selectedIndexValue}/${selectedPolicy}`,
      {
        responseKind: 'turbo-stream',
      }
    );
    request.perform;
  }

  // Updates Itemizable dropdowns if both Policy and Type are set
  setItemizable(_event) {
    const selectedPolicy = this.policyTarget.value;
    if (selectedPolicy.length === 0) {
      // Reset form if no policy is selected
      this.taxFieldTargets.forEach((taxField) => {
        taxField.innerHTML = '';
      });
      this.referenceTypeTarget.value = 'Policy';
      this.referenceIdTarget.value = '';
      this.totalTarget.value = '';
      this.totalTarget.disabled = true;
      return;
    }

    // Reset the ID value on change (helps clear for setTaxPercentage, etc)
    this.referenceIdTarget.value = '';

    const request = get(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/set_itemizable/${this.selectedIndexValue}/${selectedPolicy}/${this.referenceTypeTarget.value}`,
      {
        responseKind: 'turbo-stream',
      }
    );
    request.perform;
  }

  async validateTotalInvoiced(event) {
    const selectedPolicy = this.policyTarget.value;

    let total = '0.00';

    if (this.hasTotalTarget && this.totalTarget.value.length > 0) {
      total = this.totalTarget.value;
    }

    const request = post(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/validate_total_invoiced`,
      {
        responseKind: 'turbo-stream',
        body: {
          index: this.selectedIndexValue,
          total: total,
          reference_type: this.referenceTypeTarget.value || null,
          reference_id: this.referenceIdTarget.value || null,
          policy_id: selectedPolicy,
        },
      }
    );

    request.perform;
  }

  setTaxPercentages() {
    // Note: Race conditions here when Reference Type is changing to Policy -- the ID may have not cleared out yet from setItemizable
    const refType = this.referenceTypeTarget.value;
    let refId = null;

    if (refType == 'Policy') {
      refId = this.policyTarget.value;
    } else {
      refId = this.referenceIdTarget.value;
    }

    if (refType && refId) {
      const request = post(
        `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/set_tax_percentages`,
        {
          responseKind: 'turbo-stream',
          body: {
            index: this.selectedIndexValue,
            ref_type: refType,
            ref_id: refId,
            policy_id: this.policyTarget.value,
            amount_to_invoice: this.totalTarget.value,
          },
        }
      );
      request.perform;
    }
  }
}
